import React from "react";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import PropTypes from "prop-types";
import { isTablet, isMobile } from "react-device-detect";
import { graphql } from "gatsby";
import { compose } from "recompose";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import { withAuthorization } from "../components/Session";
import Content, { HTMLContent } from "../components/Content";
import EmbedYouTubeVideo from "../components/EmbedYouTubeVideo";
import PageHeader from "../components/PageHeader";

// const ALLOWED_IDS = [
//   "QMSCVu9vm8VfcHj8yYQeYuHZ5IK2", // me dev
//   "C49mkwskhiVlwdddYPApHvSvQk03", // me prod
//   "gyWBkE5SXNbx4b7F7dTBdEtflH62", // kristie prod
//   "byroJmtYShhXU0cH2dgDnmPyBUj1", // catherine
//   "10ZkOjxeGJZriWAE3apNZ09QAbT2", // kat
//   "C6iC0lKULKYckrWUKWlQZgAFH682", // rachelle
//   "1CfzhyyJGpVfTGjpKv56ZUcxqdD2", // amanda
//   "L09jhjIOUVeDkXUuhpsCZChI8Cr2", // lisa
// ];

const isTouch = isMobile || isTablet;

function BasixPostTemplateBase({
  title,
  url,
  helmet,
  long_name,
  content,
  contentComponent,
}) {
  const PostContent = contentComponent || Content;

  const desktopStyle = {};
  if (!isTouch) {
    desktopStyle.display = "flex";
    desktopStyle.flexDirection = "column";
    desktopStyle.alignItems = "center";
    desktopStyle.flex = "1";
  }

  return (
    <>
      {helmet || ""}
      <div className="basix-detail-page">
        <PageHeader title={`Goal #${title}`} />
        <div
          style={{
            backgroundColor: "transparent",
            padding: 0,
            paddingBottom: 0,
            ...desktopStyle,
          }}
        >
          <div
            className="container content"
            style={{ padding: "2rem", color: "white" }}
          >
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <EmbedYouTubeVideo url={url} title={title} autoplay={true} />
                <h2 className="title is-size-2 has-text-weight-bold is-bold-light has-text-centered">
                  {long_name}
                </h2>
                <PostContent content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BasixPostTemplateBase.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

const condition = (authUser) => !!authUser;

const BasixPostTemplate = compose(withAuthorization(condition))(
  BasixPostTemplateBase
);

const BasixPost = ({ data }) => {
  const { markdownRemark: post } = data;

  const content = (
    <BasixPostTemplate
      content={post.html}
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      url={post.frontmatter.url}
      short_name={post.frontmatter.short_name}
      long_name={post.frontmatter.long_name}
      helmet={
        <Helmet titleTemplate="%s | Back to Basix | Kristielengel.com">
          <title>{`${post.frontmatter.title}`}</title>
          <meta
            name="description"
            content={`${post.frontmatter.description}`}
          />
          <meta name="og:video" content={post.frontmatter.url} />
          <meta name="og:title" content={post.frontmatter.title} />
        </Helmet>
      }
    />
  );

  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) => {
        return modal ? content : <Layout>{content}</Layout>;
      }}
    </ModalRoutingContext.Consumer>
  );
};

BasixPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BasixPost;

export const pageQuery = graphql`
  query BasixPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        url
        short_name
        long_name
      }
    }
  }
`;
